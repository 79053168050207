import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AppTheme } from '@emrm/settings/types';
import { GoogleAnalyticsService } from '@emrm/google-analytics';
import { SettingsService } from '@emrm/settings/service';
import { AuthService } from '@emrm/core/auth/service';

@Component({
	selector: 'emrm-user-menu',
	templateUrl: 'user-menu.component.html',
	styleUrls: ['./user-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent implements OnDestroy {
	menuOpened = false;
	theme: AppTheme = AppTheme.Light;

	themesMenu = [
		{
			title: 'Светлый',
			icon: 'light-mode',
			theme: AppTheme.Light,
		},
		{
			title: 'Тёмный',
			icon: 'dark-mode',
			theme: AppTheme.Dark,
		},
		{
			title: 'Как в системе',
			icon: 'desktop-computer',
			theme: AppTheme.System,
		},
	];

	private readonly settings$: Subscription;

	constructor(
		private readonly router: Router,
		private readonly authService: AuthService,
		private readonly settingsService: SettingsService,
		private readonly googleAnalytics: GoogleAnalyticsService,
	) {
		this.settingsService.initSettings();

		this.settings$ = this.settingsService.settings$.subscribe((settings) => {
			if (settings) {
				this.theme = settings.global.theme;
				this.settingsService.setTheme(settings.global.theme);
			}
		});
	}

	ngOnDestroy() {
		this.settings$.unsubscribe();
	}

	getUserAvatar() {
		return this.authService.getUserAvatar();
	}

	onLogoutClick() {
		this.authService.logout();
		this.googleAnalytics.send('vyhod_iz_servisa');
	}

	onProfileClick() {
		const sessionUser = this.authService.getDecodedToken();
		this.router.navigate(['/manage/users/detail', sessionUser?.id]);
		this.googleAnalytics.send('perehod_v_moi_profil');
	}

	onThemeChangeClick(theme: AppTheme) {
		this.theme = theme;

		this.settingsService.setTheme(theme);
		this.settingsService.saveTheme(theme);

		this.googleAnalytics.send('smena_temy');
	}

	onMenuCloseClick(event: Event) {
		event.preventDefault();
	}
}
