import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

import { ChatGptMessage } from '@emrm/chat-gpt/types';
import { GoogleAnalyticsService } from '@emrm/google-analytics';

@Component({
	selector: 'emrm-chat-gpt-footer',
	templateUrl: './chat-gpt-footer.component.html',
	styleUrls: ['./chat-gpt-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatGptFooterComponent {
	inputText = '';

	@Input() messages: ChatGptMessage[] | null = [];
	@Input() loading: boolean | null = false;

	@Output() sendClick = new EventEmitter();
	@Output() clearClick = new EventEmitter();

	constructor(private googleAnalytics: GoogleAnalyticsService) {}

	onSendClick() {
		this.sendClick.emit(this.inputText);
		this.inputText = '';
		this.googleAnalytics.send('gpt_otpravka_voprosa');
	}

	onClearClick() {
		this.inputText = '';
		this.clearClick.emit();
		this.googleAnalytics.send('gpt_ochistka');
	}
}
