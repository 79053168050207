import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { FEATURE_KEY } from './state';
import { reducers } from './reducers';
import { ChatGptEffect } from './effects';
import { ChatGptMessageService } from './store.service';

@NgModule({
	imports: [
		StoreModule.forFeature(FEATURE_KEY, reducers),
		EffectsModule.forFeature([ChatGptEffect]),
	],
	providers: [ChatGptMessageService],
})
export class ChatGptStoreModule {}
