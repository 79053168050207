<div class="footer-container">
	<ng-container *ngIf="messages?.length">
		<button
			euiIconButton
			color="secondary"
			size="md"
			shape="round"
			(click)="onClearClick()">
			<eui-icons name="mop"></eui-icons>
		</button>
	</ng-container>

	<eui-input-group>
		<input
			euiInput
			placeholder="Введите запрос"
			type="text"
			[disabled]="!!loading"
			[error]="false"
			(keyup.enter)="onSendClick()"
			[(ngModel)]="inputText" />
	</eui-input-group>

	<button
		euiIconButton
		type="submit"
		color="primary"
		size="md"
		shape="round"
		[disabled]="!inputText.length || !!loading"
		(click)="onSendClick()">
		<eui-icons name="arrow-up"></eui-icons>
	</button>
</div>
