import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { of } from 'rxjs';

import { AppRoute } from '@emrm/common/types/ui';
import { PermissionService } from '@emrm/permissions/service';

@Component({
	selector: 'emrm-menu',
	templateUrl: 'menu.component.html',
	styleUrls: ['./menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
	@Input() apps: AppRoute[] = [];

	constructor(private permissionService: PermissionService) {}

	trackByFn(index: number) {
		return index;
	}

	canAccessToApp(appCode: string) {
		// TODO fix
		return appCode === 'manage'
			? of(true)
			: this.permissionService.hasAccessToApp$(appCode);
	}
}
