import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ChatGptMessage, ChatGptRole } from '@emrm/chat-gpt/types';

import { ChatGptState } from './state';
import * as ChatGptActions from './actions';

export const adapter = createEntityAdapter<ChatGptMessage>({
	selectId: (e) => e.message_id,
	sortComparer: false,
});

export const initialState: ChatGptState = adapter.getInitialState({
	loading: false,
	error: null,
});

const apiLeadsFailure = (state: ChatGptState, action: { error: string }) => {
	return {
		...state,
		loading: false,
		error: action.error,
	};
};

export const reducers = createReducer(
	initialState,
	on(ChatGptActions.loadMessagesHistorySuccess, (state, action) => {
		return adapter.setAll(action.messages, {
			...state,
			loading: false,
		});
	}),
	on(ChatGptActions.loadMessagesHistoryFailure, apiLeadsFailure),

	on(ChatGptActions.clearMessagesHistorySuccess, (state) => {
		return adapter.setAll([], {
			...state,
			loading: false,
		});
	}),
	on(ChatGptActions.clearMessagesHistoryFailure, apiLeadsFailure),

	on(ChatGptActions.sendMessage, (state, action) => {
		return adapter.addOne(
			{
				role: ChatGptRole.user,
				content: action.message,
				message_id: String(new Date().getMilliseconds()),
			},
			{
				...state,
				loading: true,
			},
		);
	}),
	on(ChatGptActions.sendMessageSuccess, (state, action) => {
		return adapter.addOne(action.messages, {
			...state,
			loading: false,
		});
	}),
	on(ChatGptActions.sendMessageFailure, apiLeadsFailure),
);

export const { selectAll, selectEntities } = adapter.getSelectors();
