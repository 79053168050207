import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
	HTTP_INTERCEPTORS,
	HttpClientModule,
	HttpClientXsrfModule,
} from '@angular/common/http';

import localeRu from '@angular/common/locales/ru';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeRu, 'ru');

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EntityDataModule } from '@ngrx/data';
import { entityConfig } from './entity-metadata';

import { environments } from '@emrm/core/environments';
import {
	EtagiDataInterceptor,
	HttpErrorInterceptor,
	JwtInterceptor,
} from '@emrm/core/interceptors';
import { SettingsModule } from '@emrm/settings/service';
import { LayoutModule } from '@emrm/ui/components/layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		HttpClientXsrfModule.withOptions({
			cookieName: 'XSRF-Token',
			headerName: 'XSRF-Token',
		}),
		AppRoutingModule,
		LayoutModule,
		SettingsModule,

		StoreModule.forRoot({}, {}),
		EffectsModule.forRoot([]),
		StoreRouterConnectingModule.forRoot(),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: !isDevMode(),
			connectInZone: true,
		}),
		EntityDataModule.forRoot(entityConfig),
	],
	providers: [
		{
			provide: 'ENVIRONMENTS',
			useValue: environments,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: EtagiDataInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
