export interface ChatGptMessage {
	role: ChatGptRole;
	content: string;
	message_id: string;
}

export interface ChatGptMessageHistory {
	messages_history: ChatGptMessage[];
}

export interface ChatGptMessageResponse {
	message: string;
}

export enum ChatGptRole {
	user = 'user',
	assistant = 'assistant',
}
