<ng-container *ngIf="show">
	<ul class="header-submenu">
		<li
			*ngFor="let route of routes; trackBy: trackByFn"
			[routerLink]="route.link"
			routerLinkActive="active">
			<a (click)="onSubmenuRouteClick(route)">{{ route.name }}</a>
		</li>
	</ul>
</ng-container>
