<div class="header-container">
	<p class="header-container__title">MrmGPT</p>
	<button
		euiIconButton
		color="primary"
		size="lg"
		shape="round"
		(click)="onHideClick()">
		<eui-icons name="chevron-down"></eui-icons>
	</button>
</div>
