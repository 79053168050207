<div
	#menuTrigger
	class="user-menu__trigger"
	(cdkMenuOpened)="menuOpened = true"
	(cdkMenuClosed)="menuOpened = false"
	[cdkMenuTriggerFor]="userMenuRef">
	<ng-template #userAvatar>
		<eui-avatar
			size="md"
			[avatarUrl]="getUserAvatar()"
			[onlineStatus]="'online'"></eui-avatar>
	</ng-template>

	<ng-container *ngIf="menuOpened; else menuClosedIcon">
		<ng-container *ngTemplateOutlet="userAvatar"></ng-container>
		<eui-icons name="chevron-up" [size]="20"></eui-icons>
	</ng-container>
	<ng-template #menuClosedIcon>
		<ng-container *ngTemplateOutlet="userAvatar"></ng-container>
		<a href="#" (click)="onMenuCloseClick($event)">
			<eui-icons name="chevron-down" [size]="20"></eui-icons>
		</a>
	</ng-template>
</div>

<ng-template #userMenuRef>
	<eui-menu class="user-menu" cdkMenu>
		<eui-menu-item
			cdkMenuItem
			icon="user"
			title="Мой профиль"
			(click)="onProfileClick()">
		</eui-menu-item>
		<eui-menu-item
			cdkMenuItem
			icon="dark-mode"
			title="Внешний вид"
			[cdkMenuTriggerFor]="themesMenuRef"
			[submenu]="true">
		</eui-menu-item>

		<div class="divider">
			<eui-divider></eui-divider>
		</div>

		<eui-menu-item
			cdkMenuItem
			icon="log-out"
			title="Выйти из сервиса"
			(click)="onLogoutClick()">
		</eui-menu-item>
	</eui-menu>
</ng-template>

<ng-template #themesMenuRef>
	<eui-menu cdkMenu>
		<ng-container *ngFor="let themeMenu of themesMenu">
			<eui-menu-item
				cdkMenuItem
				[title]="themeMenu.title"
				[icon]="themeMenu.icon"
				[active]="theme === themeMenu.theme"
				(click)="onThemeChangeClick(themeMenu.theme)">
			</eui-menu-item>
		</ng-container>
	</eui-menu>
</ng-template>
