import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostListener,
	Input,
	Output,
} from '@angular/core';

@Component({
	selector: 'emrm-resize',
	template: `
		<div
			class="resize-container"
			[style.width.px]="width"
			[style.height.px]="height">
			<div
				class="resizer top-left"
				(mousedown)="onResizeStart($event, resizeXY)"></div>
			<div
				class="resizer top"
				(mousedown)="onResizeStart($event, resizeY)"></div>
			<div
				class="resizer left"
				(mousedown)="onResizeStart($event, resizeX)"></div>
			<ng-content></ng-content>
		</div>
	`,
	styleUrls: ['./resize.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResizeComponent {
	@Input() minWidth = 0;
	@Input() maxWidth = window.innerWidth / 2;
	@Input() minHeight = 0;
	@Input() maxHeight = window.innerHeight - 110;
	@Input() width = 150;
	@Input() height = 150;

	@Output() sizeChange = new EventEmitter();

	private px = 0;
	private py = 0;
	private draggingCorner = false;
	private resizer!: (offsetX: number, offsetY: number) => void;

	onResizeStart(event: MouseEvent, func?: any) {
		event.preventDefault();
		event.stopPropagation();

		this.draggingCorner = true;
		this.px = event.clientX;
		this.py = event.clientY;

		this.resizer = func;
	}

	@HostListener('document:mousemove', ['$event'])
	onCornerMove(event: MouseEvent) {
		if (!this.draggingCorner) {
			return;
		}

		const offsetX = event.clientX - this.px;
		const offsetY = event.clientY - this.py;

		this.resizer(offsetX, offsetY);

		this.px = event.clientX;
		this.py = event.clientY;
	}

	@HostListener('document:mouseup', ['$event'])
	onCornerRelease() {
		this.draggingCorner = false;

		this.sizeChange.emit({
			width: this.width,
			height: this.height,
		});
	}

	resizeXY(offsetX: number, offsetY: number) {
		this.resizeX(offsetX, offsetY);
		this.resizeY(offsetX, offsetY);
	}

	resizeX(offsetX: number, offsetY: number) {
		if (this.width < this.minWidth) {
			if (offsetX < 0) {
				this.width -= offsetX;
			}
			return;
		}

		this.width -= offsetX;

		if (this.width < this.minWidth) {
			this.width = this.minWidth;
		}

		if (this.width > this.maxWidth) {
			this.width = this.maxWidth;
		}
	}

	resizeY(offsetX: number, offsetY: number) {
		if (this.height < this.minHeight) {
			if (offsetY < 0) {
				this.height -= offsetY;
			}
			return;
		}

		this.height -= offsetY;

		if (this.height < this.minHeight) {
			this.height = this.minHeight;
		}

		if (this.height > this.maxHeight) {
			this.height = this.maxHeight;
		}
	}
}
