<ng-template #examples>
	<emrm-chat-gpt-examples (sendClick)="onSendClick($event)"></emrm-chat-gpt-examples>
</ng-template>

<ng-container *ngIf="messages?.length; else examples">
	<div class="content">
		<ng-container
			*ngFor="let message of messages; index as i; trackBy: trackByFn">
			<div class="content__chat-container">
				<div [class]="getMessageElementTypeClass(message.role)">
					<div [class]="getMessageModifierTypeClass(message.role)">
						<p>{{ message.content }}</p>
					</div>
					<ng-container *ngIf="message.role === 'assistant'">
						<emrm-chat-gpt-copy-button [text]="message.content"></emrm-chat-gpt-copy-button>
					</ng-container>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="loading">
			<emrm-chat-gpt-pending></emrm-chat-gpt-pending>
		</ng-container>
	</div>
</ng-container>
