import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIKitModule } from '@emrm/ui-kit';
import { ChatGptStoreModule } from '@emrm/chat-gpt/store';
import { ChatGptModalService } from '@emrm/chat-gpt/components/modal';

import { ChatGptPageComponent } from './chat-gpt-page.component';

@NgModule({
	declarations: [ChatGptPageComponent],
	imports: [CommonModule, UIKitModule, ChatGptStoreModule],
	exports: [ChatGptPageComponent],
	providers: [ChatGptModalService],
})
export class ChatGptPageModule {}
