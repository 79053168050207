import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatGptPageModule } from '@emrm/chat-gpt/page';
import { environments } from '@emrm/core/environments';

import { GhatGpRoutingModule } from './chat-gpt-routing.module';

@NgModule({
	imports: [CommonModule, GhatGpRoutingModule, ChatGptPageModule],
	exports: [ChatGptPageModule],
	providers: [
		{
			provide: 'ENVIRONMENTS',
			useValue: environments,
		},
	],
})
export class ChatGptModule {}
