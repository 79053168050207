import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output,
} from '@angular/core';
import { GoogleAnalyticsService } from '@emrm/google-analytics';

@Component({
	selector: 'emrm-chat-gpt-header',
	templateUrl: './chat-gpt-header.component.html',
	styleUrls: ['./chat-gpt-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatGptHeaderComponent {
	@Output() hideClick = new EventEmitter();

	constructor(private googleAnalytics: GoogleAnalyticsService) {}

	onHideClick() {
		this.hideClick.emit();
		this.googleAnalytics.send('gpt_zakryyie_chata');
	}
}
