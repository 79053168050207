import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

import { ChatGptModalComponent } from './chat-gpt-modal.component';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ChatGptModalService {
	constructor(private overlay: Overlay) {}

	show$ = new BehaviorSubject({ view: false });

	private overlayRef!: OverlayRef;

	open() {
		this.show$.next({ view: true });

		const positionStrategy = this.overlay
			.position()
			.global()
			.bottom('40px')
			.right('40px');

		this.overlayRef = this.overlay.create({
			positionStrategy,
		});

		this.overlayRef.attach(new ComponentPortal(ChatGptModalComponent));
	}

	close() {
		this.show$.next({ view: false });

		this.overlayRef.detach();
	}

	getShow() {
		return this.show$.asObservable();
	}
}
