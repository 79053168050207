import { Inject, Injectable } from '@angular/core';

import { Environments } from '@emrm/core/environments';
import { ApiService } from '@emrm/common/services/api';
import {
	ChatGptMessage,
	ChatGptMessageHistory,
	ChatGptMessageResponse,
} from '@emrm/chat-gpt/types';

@Injectable()
export class ChatGptMessageService {
	private readonly apiUrl;

	constructor(
		@Inject('ENVIRONMENTS') private readonly environment: Environments,
		private apiService: ApiService,
	) {
		this.apiUrl = this.environment.chatGptApiUrl;
	}

	send(message: string) {
		return this.apiService.post$<ChatGptMessage>(`${this.apiUrl}/chat`, {
			message: message,
		});
	}

	createChat() {
		return this.apiService.get$<ChatGptMessageResponse>(
			`${this.apiUrl}/create_new_chat`,
		);
	}

	loadHistory() {
		return this.apiService.get$<ChatGptMessageHistory>(
			`${this.apiUrl}/history`,
		);
	}
}
