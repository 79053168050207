<div class="container">
	<emrm-resize
		[minWidth]="minWidth"
		[minHeight]="minHeight"
		[width]="width"
		[height]="height"
		(sizeChange)="onWindowSizeChange($event)">
		<div class="container__elements">
			<emrm-chat-gpt-header (hideClick)="onHideClick()"> </emrm-chat-gpt-header>
			<div
				class="container__content"
				id="content"
				#scrollMe
				[scrollTop]="scrollMe.scrollHeight">
				<emrm-chat-gpt-content
					[messages]="messages$ | async"
					[loading]="loading$ | async"
					(sendClick)="onSendMessageClick($event)">
				</emrm-chat-gpt-content>
			</div>
			<emrm-chat-gpt-footer
				[messages]="messages$ | async"
				[loading]="loading$ | async"
				(sendClick)="onSendMessageClick($event)"
				(clearClick)="onClearClick()">
			</emrm-chat-gpt-footer>
		</div>
	</emrm-resize>
</div>
