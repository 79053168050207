import { Injectable } from '@angular/core';
import { catchError, map, of, exhaustMap, switchMap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as StoreActions from './actions';
import { SettingsService } from './store.service';

@Injectable()
export class SettingsEffects {
	constructor(
		private actions$: Actions,
		private settingsService: SettingsService,
	) {}

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StoreActions.loadSettings),
			switchMap((action) => {
				return this.settingsService.load(action.id).pipe(
					map((response) => {
						return StoreActions.loadSettingsSuccess({
							settings: response,
						});
					}),
					catchError((error) =>
						of(
							StoreActions.loadSettingsFailure({
								error: error.message,
							}),
						),
					),
				);
			}),
		),
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StoreActions.saveSettings),
			exhaustMap((action) => {
				return this.settingsService.save(action.id, action.settings).pipe(
					map(() => {
						return StoreActions.saveSettingsSuccess({
							settings: action.settings,
						});
					}),
					catchError((error) =>
						of(
							StoreActions.saveSettingsFailure({
								error: error.message,
							}),
						),
					),
				);
			}),
		),
	);
}
