import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GoogleAnalyticsService } from '@emrm/google-analytics';
import { ChatGptModalService } from '@emrm/chat-gpt/components/modal';

@Component({
	selector: 'emrm-chat-gpt',
	templateUrl: './chat-gpt-page.component.html',
	styleUrls: ['./chat-gpt-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatGptPageComponent {
	chatView$ = this.chatGptModalService.getShow();

	constructor(
		private readonly googleAnalytics: GoogleAnalyticsService,
		private chatGptModalService: ChatGptModalService,
	) {}

	onToggleChatClick(event: Event, chatView: boolean) {
		this.googleAnalytics.send('gpt_ikonka_vyzova');

		if (!chatView) {
			this.chatGptModalService.open();
		} else {
			this.chatGptModalService.close();
		}

		event.preventDefault();
	}
}
