import { NgModule } from '@angular/core';

import { SettingsStoreModule } from '@emrm/settings/store';

import { SettingsService } from './settings.service';

@NgModule({
	imports: [SettingsStoreModule],
	providers: [SettingsService],
})
export class SettingsModule {}
