import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CdkMenuModule } from '@angular/cdk/menu';

import { UIKitModule } from '@emrm/ui-kit';
import { ChatGptModule } from '@emrm/chat-gpt/module';
import { PermissionsStoreModule } from '@emrm/permissions/store';

import { HeaderComponent } from './header.component';
import { SubmenuComponent } from './submenu/submenu.component';
import { MenuComponent } from './menu/menu.component';
import { UserMenuComponent } from './user-menu/user-menu.component';

@NgModule({
	declarations: [
		HeaderComponent,
		MenuComponent,
		SubmenuComponent,
		UserMenuComponent,
	],
	imports: [
		CommonModule,
		CdkMenuModule,
		RouterModule,
		UIKitModule,
		ChatGptModule,
		PermissionsStoreModule,
	],
	exports: [HeaderComponent],
})
export class HeaderModule {}
