import {
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import {
	clearMessagesHistory,
	loadMessagesHistory,
	selectChatGptLoading,
	selectChatGptMessages,
	sendMessage,
} from '@emrm/chat-gpt/store';
import { ChatGptMessage } from '@emrm/chat-gpt/types';
import { SettingsService } from '@emrm/settings/service';
import { ChatGptModalService } from './chat-gpt-modal.service';

@Component({
	selector: 'emrm-chat-gpt',
	templateUrl: './chat-gpt-modal.component.html',
	styleUrls: ['./chat-gpt-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatGptModalComponent implements OnInit, OnDestroy {
	messages$: Observable<ChatGptMessage[]>;
	loading$: Observable<boolean>;

	minWidth = 300;
	minHeight = 500;

	width = 400;
	height = 590;

	private readonly settings$: Subscription;

	constructor(
		private readonly store: Store,
		private readonly settingsService: SettingsService,
		private readonly chatGptModalService: ChatGptModalService,
	) {
		this.messages$ = this.store.pipe(select(selectChatGptMessages));
		this.loading$ = this.store.pipe(select(selectChatGptLoading));

		this.settings$ = this.settingsService.settings$.subscribe((settings) => {
			if (settings) {
				this.width = settings.chatGpt.width;
				this.height = settings.chatGpt.height;
			}
		});
	}

	ngOnInit() {
		this.store.dispatch(loadMessagesHistory());
	}

	ngOnDestroy() {
		this.settings$.unsubscribe();
	}

	onWindowSizeChange(e: { width: number; height: number }) {
		this.settingsService.setChatGptWindowSize(e.width, e.height);
	}

	onHideClick() {
		this.chatGptModalService.close();
	}

	onSendMessageClick(event: string) {
		this.store.dispatch(sendMessage({ message: event }));
	}

	onClearClick() {
		this.store.dispatch(clearMessagesHistory());
	}
}
