import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';

import { Store } from '@ngrx/store';

import * as UserPermissionActions from '@emrm/permissions/store';

import { AppRoute } from '@emrm/common/types/ui';
import { REPORTS_APP_CODE } from '@emrm/reports/types';

@Component({
	selector: 'emrm-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
	// TODO: move to ...
	apps: AppRoute[] = [
		{
			code: 'budgets',
			link: '/budgets',
			name: 'Бюджеты',
			routes: [
				{ name: 'Внесение', link: '/budgets/modification' },
				// { name: 'Анализ', link: '/budgets/analysis' },
			],
		},
		{
			code: REPORTS_APP_CODE,
			link: '/reports',
			name: 'Сводка',
			routes: [],
		},
		{
			code: 'leadmonitor',
			link: '/leads',
			name: 'Заявки',
			routes: [],
		},
		{
			code: 'manage',
			link: '/manage',
			name: 'Пользователи',
			routes: [
				{ name: 'Пользователи', link: '/manage/users' },
				{ name: 'Команды', link: '/manage/teams' },
			],
		},
	];
	submenuRoutes: Partial<AppRoute>[] = [];
	submenuShow = false;
	activeRoute = '';
	activeSubmenu = '';

	private destroy$ = new Subject();

	constructor(
		private store: Store,
		private cdr: ChangeDetectorRef,
		private router: Router,
	) {
		this.router.events
			.pipe(
				takeUntil(this.destroy$),
				filter((event) => event instanceof NavigationEnd),
			)
			.subscribe((event) => {
				this.closeSubmenu();

				const routeParts = (event as NavigationEnd).url
					.split('/')
					.filter((x: unknown) => x);

				this.activeRoute = routeParts[0];

				const activeRouteSubmenu = this.apps.find(
					(x) => x.code === this.activeRoute,
				);

				if (
					activeRouteSubmenu &&
					activeRouteSubmenu.routes &&
					activeRouteSubmenu.routes.length > 0
				) {
					this.openSubmenu(activeRouteSubmenu);
				}

				this.cdr.detectChanges();
			});
	}

	ngOnInit() {
		this.store.dispatch(UserPermissionActions.loadPermissions());
	}

	ngOnDestroy() {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	private openSubmenu(submenu: AppRoute) {
		this.submenuShow = true;
		this.activeSubmenu = submenu.code;
		this.submenuRoutes = submenu.routes;
	}

	private closeSubmenu() {
		this.submenuShow = false;
		this.activeSubmenu = '';
		this.submenuRoutes = [];
	}
}
