import { Inject, Injectable } from '@angular/core';

import { Environments } from '@emrm/core/environments';
import { ApiService } from '@emrm/common/services/api';
import { Settings } from '@emrm/settings/types';

@Injectable()
export class SettingsService {
	constructor(
		@Inject('ENVIRONMENTS') private readonly environment: Environments,
		private apiService: ApiService,
	) {}

	load(id: number) {
		return this.apiService.get$<Settings>(
			`${this.environment.settingsApiUrl}/setting/${id}`,
		);
	}

	save(id: number, settings: Settings) {
		const body = {
			user_id: id,
			data: settings,
		};

		return this.apiService.post$<{ success: true }>(
			`${this.environment.settingsApiUrl}/setting`,
			body,
		);
	}
}
