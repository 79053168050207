import { createAction, props } from '@ngrx/store';

import { ChatGptMessage } from '@emrm/chat-gpt/types';

const ACTIONS_CODE = 'Chat-gpt';
const ACTIONS_API_CODE = `[${ACTIONS_CODE} API]`;

export const loadMessagesHistory = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Load Message History`,
);

export const loadMessagesHistorySuccess = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Load Message History Success`,
	props<{ messages: ChatGptMessage[] }>(),
);

export const loadMessagesHistoryFailure = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Load Message History Failure`,
	props<{ error: string }>(),
);

export const clearMessagesHistory = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Clear Message History`,
);

export const clearMessagesHistorySuccess = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Clear Message History Success`,
	props<{ message: string }>(),
);

export const clearMessagesHistoryFailure = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Clear Message History Failure`,
	props<{ error: string }>(),
);

export const sendMessage = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Send Message`,
	props<{ message: string }>(),
);

export const sendMessageSuccess = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Send Message Success`,
	props<{ messages: ChatGptMessage }>(),
);

export const sendMessageFailure = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Send Message Failure`,
	props<{ error: string }>(),
);
