import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderModule } from '@emrm/ui/components/header';

import { LayoutComponent } from './layout.component';

@NgModule({
	declarations: [LayoutComponent],
	imports: [CommonModule, RouterModule, HeaderModule],
	exports: [LayoutComponent],
})
export class LayoutModule {}
