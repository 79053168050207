import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { environments } from '@emrm/core/environments';

import { FEATURE_KEY } from './state';
import { reducers } from './reducers';
import { SettingsEffects } from './effects';
import { SettingsService } from './store.service';

@NgModule({
	imports: [
		StoreModule.forFeature(FEATURE_KEY, reducers),
		EffectsModule.forFeature([SettingsEffects]),
	],
	providers: [
		SettingsService,
		{
			provide: 'ENVIRONMENTS',
			useValue: environments,
		},
	],
})
export class SettingsStoreModule {}
