import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { Settings } from '@emrm/settings/types';

import { SettingsState, getInitialSettings } from './state';
import * as StoreActions from './actions';

export const adapter = createEntityAdapter<Settings>();

export const initialState: SettingsState = adapter.getInitialState({
	loading: false,
	error: null,
	settings: getInitialSettings(),
});

const apiFailure = (state: SettingsState, action: { error: string }) => {
	return {
		...state,
		loading: false,
		error: action.error,
	};
};

export const reducers = createReducer(
	initialState,
	on(StoreActions.loadSettings, (state) => {
		return {
			...state,
			loading: true,
			error: null,
		};
	}),
	on(StoreActions.loadSettingsSuccess, (state, action) => {
		return {
			...state,
			settings: action.settings,
			loading: false,
		};
	}),
	on(StoreActions.loadSettingsFailure, apiFailure),

	on(StoreActions.saveSettingsSuccess, (state, action) => {
		return {
			...state,
			settings: action.settings,
			loading: false,
		};
	}),
	on(StoreActions.saveSettingsFailure, apiFailure),
);

export const { selectAll, selectEntities } = adapter.getSelectors();
