import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ChatGptState, FEATURE_KEY } from './state';
import * as fromReducers from './reducers';

export const featureMessagesSelector =
	createFeatureSelector<ChatGptState>(FEATURE_KEY);

export const selectChatGptLoading = createSelector(
	featureMessagesSelector,
	(state) => state.loading,
);

export const selectChatGptError = createSelector(
	featureMessagesSelector,
	(state) => state.error,
);

export const selectChatGptMessages = createSelector(
	featureMessagesSelector,
	fromReducers.selectAll,
);
