import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

import { ChatGptMessage } from '@emrm/chat-gpt/types';

@Component({
	selector: 'emrm-chat-gpt-content',
	templateUrl: './chat-gpt-content.component.html',
	styleUrls: ['./chat-gpt-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatGptContentComponent {
	@Input() messages: ChatGptMessage[] | null = [];
	@Input() loading: boolean | null = false;
	@Output() sendClick = new EventEmitter();

	onSendClick(event: string) {
		this.sendClick.emit(event);
	}

	trackByFn(index: number) {
		return index;
	}

	getMessageElementTypeClass(type: string) {
		return `content__chat-container__${type}-message-container`;
	}

	getMessageModifierTypeClass(type: string) {
		return `content__chat-container__${type}-message-container-${type}`;
	}
}
