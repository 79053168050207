<div class="examples-container">
	<p class="examples-container__title">Примеры запросов:</p>
	<ng-container *ngFor="let message of messages">
		<div
			class="examples-container__question"
			(click)="onSendMessageClick(message)">
			<span>{{ message }}</span>
		</div>
	</ng-container>
</div>
