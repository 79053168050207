import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { AuthGuard } from '@emrm/core/guards';
import { PermissionAppGuard } from '@emrm/permissions/guards';
import { BUDGETS_APP_CODE } from '@emrm/budgets/types';
import { HANDBOOKS_APP_CODE } from '@emrm/handbooks/types';
import { LayoutComponent } from '@emrm/ui/components/layout';
import { REPORTS_APP_CODE } from '@emrm/reports/types';

export const routes: Route[] = [
	{
		path: '',
		component: LayoutComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				loadChildren: () =>
					import('@emrm/homepage').then((m) => m.HomepageModule),
			},
			{
				path: 'manage',
				canActivate: [AuthGuard],
				loadChildren: () =>
					import('@emrm/manage/module').then((m) => m.ManageModule),
			},
			{
				path: 'reports',
				canActivate: [AuthGuard, PermissionAppGuard],
				data: { appCode: REPORTS_APP_CODE },
				loadChildren: () =>
					import('@emrm/reports/module').then((m) => m.ReportsModule),
			},
			{
				path: 'leads',
				canActivate: [AuthGuard, PermissionAppGuard],
				data: { appCode: 'leadmonitor' },
				loadChildren: () =>
					import('@emrm/leads-monitor').then((m) => m.LeadsMonitorModule),
			},
			{
				path: 'handbooks',
				canActivate: [AuthGuard, PermissionAppGuard],
				data: { appCode: HANDBOOKS_APP_CODE },
				loadChildren: () =>
					import('@emrm/handbooks/module').then((m) => m.HandbooksModule),
			},
			{
				path: 'budgets',
				canActivate: [AuthGuard, PermissionAppGuard],
				data: { appCode: BUDGETS_APP_CODE },
				loadChildren: () =>
					import('@emrm/budgets/module').then((m) => m.BudgetsModule),
			},
		],
	},
	{
		path: 'auth',
		loadChildren: () =>
			import('@emrm/core/auth/pages/sign-in').then((m) => m.SignInModule),
	},
	{ path: '**', redirectTo: '' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
