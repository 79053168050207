import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'emrm-chat-gpt-pending',
	template: `
		<div class="pending">
			<div class="typing-loader"></div>
		</div>
		<p class="pending-text">MrmGPT размышляет...</p>
	`,
	styleUrls: ['./chat-gpt-pending.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatGptPendingComponent {}
