import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output,
} from '@angular/core';

@Component({
	selector: 'emrm-chat-gpt-examples',
	templateUrl: './chat-gpt-examples.component.html',
	styleUrls: ['./chat-gpt-examples.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatGptExamplesComponent {
	messages: string[] = [
		'Напиши текст для поста о недвижимости',
		'Расскажи,  как риелтору вести соцсети',
		'Посоветуй фильмы после тяжелого рабочего дня',
	];

	@Output() sendClick = new EventEmitter();

	onSendMessageClick(message: string) {
		this.sendClick.emit(message);
	}
}
