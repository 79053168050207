import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
} from '@angular/core';

@Component({
	selector: 'emrm-chat-gpt-copy-button',
	template: `
		<button
			euiIconButton
			color="transparent-grey"
			size="sm"
			shape="square"
			(click)="onCopyMessageClick()">
			<ng-container *ngIf="copied; else copy">
				<eui-icons name="check"></eui-icons>
			</ng-container>
			<ng-template #copy>
				<eui-icons name="copy"></eui-icons>
			</ng-template>
		</button>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyButtonComponent {
	constructor(private changeDetection: ChangeDetectorRef) {}

	@Input() text = '';
	copied = false;

	onCopyMessageClick() {
		this.copyToClipboard(this.text);

		this.copied = true;

		setTimeout(() => {
			this.copied = false;
			this.changeDetection.detectChanges();
		}, 3000);
	}

	private copyToClipboard(value: string) {
		navigator.clipboard.writeText(value);
	}
}
