import { EntityState } from '@ngrx/entity';

import { ChatGptMessage } from '@emrm/chat-gpt/types';

export const FEATURE_KEY = 'chat_gpt';

export interface ChatGptState extends EntityState<ChatGptMessage> {
	loading: boolean;
	error: string | null;
}
