import { Injectable } from '@angular/core';
import { catchError, map, of, switchMap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ChatGptMessageService } from './store.service';
import * as ChatGptActions from './actions';

@Injectable()
export class ChatGptEffect {
	constructor(
		private actions$: Actions,
		private chatGptMessageService: ChatGptMessageService,
	) {}

	loadMessageHistory$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ChatGptActions.loadMessagesHistory),
			switchMap(() =>
				this.chatGptMessageService.loadHistory().pipe(
					map((response) =>
						ChatGptActions.loadMessagesHistorySuccess({
							messages: response.messages_history,
						}),
					),
					catchError((error) =>
						of(
							ChatGptActions.loadMessagesHistoryFailure({
								error: error.error,
							}),
						),
					),
				),
			),
		),
	);

	clearMessageHistory$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ChatGptActions.clearMessagesHistory),
			switchMap(() =>
				this.chatGptMessageService.createChat().pipe(
					map((response) =>
						ChatGptActions.clearMessagesHistorySuccess({
							message: response.message,
						}),
					),
					catchError((error) =>
						of(
							ChatGptActions.clearMessagesHistoryFailure({
								error: error.error,
							}),
						),
					),
				),
			),
		),
	);

	sendMessage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ChatGptActions.sendMessage),
			switchMap((action) =>
				this.chatGptMessageService.send(action.message).pipe(
					map((response) =>
						ChatGptActions.sendMessageSuccess({
							messages: response,
						}),
					),
					catchError((error) =>
						of(ChatGptActions.sendMessageFailure({ error: error.error })),
					),
				),
			),
		),
	);
}
