<div class="header-menu">
	<ul class="header-menu__nav">
		<li>
			<a class="header-menu__nav-logo" [routerLink]="['/']">
				<img src="assets/icons/mrm-logo.svg" width="54px" alt="Mrm" />
			</a>
		</li>

		<ng-container *ngFor="let app of apps; trackBy: trackByFn">
			<ng-container *ngIf="canAccessToApp(app.code) | async">
				<li>
					<a
						class="header-menu__nav-route"
						[routerLink]="[app.link]"
						routerLinkActive="active">
						{{ app.name }}
					</a>
				</li>
			</ng-container>
		</ng-container>
	</ul>

	<ul class="header-menu__nav-right">
		<ng-container *ngIf="canAccessToApp('handbooks') | async">
			<li>
				<a
					class="header-menu__nav-right-route"
					routerLinkActive="active"
					[routerLink]="['/handbooks']">
					<eui-icons name="manual" [size]="20"></eui-icons>
				</a>
			</li>
		</ng-container>
		<li>
			<emrm-chat-gpt></emrm-chat-gpt>
		</li>
		<li>
			<emrm-user-menu></emrm-user-menu>
		</li>
	</ul>
</div>
