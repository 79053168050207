import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

import { AppRoute } from '@emrm/common/types/ui';

@Component({
	selector: 'emrm-submenu',
	templateUrl: 'submenu.component.html',
	styleUrls: ['./submenu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmenuComponent {
	@Input() show = false;
	@Input() routes: Partial<AppRoute>[] = [];
	@Output() submenuRouteClick = new EventEmitter();

	trackByFn(index: number, item: Partial<AppRoute>) {
		return item;
	}

	onSubmenuRouteClick(route: Partial<AppRoute>) {
		this.submenuRouteClick.emit(route);
	}
}
